import type { FC } from "react"
import React from "react"
import { useTheme } from "@chakra-ui/react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"

import { IPFS_GATEWAY } from "common/constants/ipfs"

type Props = {
  content?: string
}

const MarkdownViewer: FC<Props> = ({ content }) => {
  const theme = useTheme()

  if (!content) return null

  return (
    <ReactMarkdown
      /* eslint-disable */
      children={content}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw as any]}
      components={{
        a: ({ node, ...props }) => {
          const url =
            props?.href === "url"
              ? String(props?.children?.[0] ?? "")
              : String(props?.href)

          if (!url) return <span>{props?.href}</span>

          return (
            <a
              target="_blank"
              style={{ color: theme.colors.purple[500] }}
              {...props}
              href={url}
            />
          )
        },
        h1: ({ node, ...props }) => (
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "2em",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
            {...props}
          />
        ),
        h2: ({ node, ...props }) => (
          <h2
            style={{
              fontWeight: "bold",
              fontSize: "1.4em",
              marginBottom: "0.3em",
              marginTop: "0.5em",
            }}
            {...props}
          />
        ),
        h3: ({ node, ...props }) => (
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "1.1em",
              marginBottom: "0.3em",
              marginTop: "0.5em",
            }}
            {...props}
          />
        ),
        h4: ({ node, ...props }) => (
          <h4
            style={{
              fontWeight: "bold",
              marginBottom: "0.4em",
            }}
            {...props}
          />
        ),
        p: ({ node, ...props }) => (
          <p
            style={{
              marginBottom: "0.5em",
            }}
            {...props}
          />
        ),
        ul: ({ node, ...props }) => (
          <ul
            style={{ listStyleType: "circle", marginLeft: "20px", marginBottom: "16px" }}
            {...props}
          />
        ),
        ol: ({ node, ...props }) => (
          <ol style={{ marginLeft: "20px", marginBottom: "16px" }} {...props} />
        ),
        blockquote: ({ node, ...props }) => (
          <blockquote
            style={{
              background: "#f9f9f9",
              borderLeft: "10px solid #ccc",
              margin: "1.5em 10px",
              padding: "0.7em 10px 0.1em 10px"
            }}
            {...props}
          />
        ),
        code: ({ node, ...props }) => (
          <code
            style={{
              marginBottom: "3px",
              marginTop: "1px",
            }}
            {...props}
          />
        ),
        pre: ({ node, ...props }) => (
          <pre
            style={{
              marginBottom: "3px",
              marginTop: "1px",
              padding: "5px",
              backgroundColor: theme.colors.gray[100],
            }}
            {...props}
          />
        ),
        img: ({ node, ...props }) => {

   

          if (props?.src?.startsWith('ipfs://')) {
            props.src = IPFS_GATEWAY + props?.src.replace(':/', '')
          }

          // if title starts with ":", it is usually an emoji so the max width should be small
          const maxWidth = props.title?.charAt(0) === ":" ? "25px" : "100%"

          return <img
            style={{
              marginBottom: "10px",
              maxWidth: maxWidth,
              width: props.width ?? maxWidth,
              height: props.height ? props.height : "auto"
            }}
            {...props}
          />
          },
      }}
      /* eslint-enable */
    />
  )
}

export default MarkdownViewer
